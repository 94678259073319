<template>
    <div data-comp="Login">
        <GuestLayout>
            <Head :title="$t('auth.login.title')"/>

            <h2>{{ $t('auth.login.title') }}</h2>

            <div class="mb-4">
                {{ $t('auth.login.description') }}
            </div>

            <div v-if="status" class="status-label">
                {{ status }}
            </div>

            <form @submit.prevent="submit" class="space-y-4">
                <div>
                    <Input
                        id="email"
                        ref="email"
                        v-model="form.email"
                        :error="form.errors.email"
                        @keydown.enter.prevent
                        autocomplete="username"
                        :placeholder="$t('auth.login.email')"
                        :label="$t('auth.login.email')"
                        required
                        autofocus
                    />
                </div>

                <div class="mt-4">
                    <Input
                        id="password"
                        ref="password"
                        type="password"
                        v-model="form.password"
                        :error="form.errors.password"
                        @keydown.enter.prevent
                        autocomplete="current-password"
                        :placeholder="$t('auth.login.password')"
                        :label="$t('auth.login.password')"
                        required
                    />
                </div>

                <div class="flex justify-between">
                    <div class="flex items-center justify-center gap-2">
                        <Checkbox
                            name="remember"
                            v-model:checked="form.remember"
                            class="-mt-1.5"
                        />

                        <div class="text-sm text-gray-600">
                            {{ $t('auth.login.remember_me') }}
                        </div>
                    </div>

                    <a href="https://producthero.com/google-css" class="mt-0.5 text-xs link" target="_blank">
                        {{ $t('auth.login.register') }}
                    </a>
                </div>

                <div class="flex items-center justify-between">
                    <div class="flex items-center gap-3">
                        <div class="control">
                            <Button
                                type="submit"
                                :loading="form.processing"
                                :disabled="form.processing"
                                @click="() => submit()"
                                :class="{ 'opacity-25': form.processing }"
                            >
                                {{ $t('auth.login.login') }}
                            </Button>
                        </div>

                        <div class="control">
                            <Link :href="route('home')" class="link">
                                {{ $t('auth.login.cancel') }}
                            </Link>
                        </div>
                    </div>

                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="underline text-sm text-gray-600 link"
                    >
                        {{ $t('auth.login.forgot_password') }}
                    </Link>
                </div>
            </form>
        </GuestLayout>
    </div>
</template>

<script setup>
    import { useForm } from '@inertiajs/vue3';
    import GuestLayout from '@/Layouts/Guest.vue';
    import Checkbox from '@/Components/Checkbox.vue';
    import Input from '@/Components/Input.vue';
    import Button from '@/Components/Button.vue';
    import config from '@/config';

    defineProps({
        canResetPassword: Boolean,
        status: String,
    });

    const form = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const submit = () => {
        form.post(route('login'), {
            onFinish: () => form.reset('password'),
        });
    };
</script>

<style lang="scss" scoped>
    [data-comp=Login] {
        .status-label {
            @apply mb-4 font-medium text-sm text-green-600;
        }
        .link {
            @apply hover:text-gray-900 transition ease-in-out duration-200;
        }
    }
</style>
